import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  CategoryPostsWrapper,
  TagPageHeading,
  SectionTitle,
  CategoryName
} from './templates.style';
import PostCardList from '../components/post-card-list/post-card-list';
import ImageCardList from '../components/image-card-list/image-card-list';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';
import { generateGroupPagePath } from '../utils';

const Category = ({ pageContext, data }: any) => {
  const { category, group } = pageContext;
  const wishes = data.wishes.edges;
  const imageCards = data.imageCards.edges;
  const breadcrumbs = [
    {
      label: group,
      link: `/${generateGroupPagePath(group)}`,
    },
    {
      label: category
    }
  ];

  return (
    <Layout>
      <SEO title={category} description={`Wishes and E-Cards of ${category}`} />
      <Breadcrumbs data={breadcrumbs} />
      <CategoryPostsWrapper>
        <TagPageHeading>
          <CategoryName>{category}</CategoryName>
        </TagPageHeading>
        {!!wishes.length && (
          <>
            <SectionTitle>Wishes</SectionTitle>
            <PostCardList posts={wishes} />
          </>
        )}
        {!!imageCards.length && (
          <>
            <SectionTitle>E-Cards</SectionTitle>
            <ImageCardList posts={imageCards} />
          </>
        )}
      </CategoryPostsWrapper>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($category: String) {
    wishes: allMdx(
      limit: 2000
      sort: { fields: frontmatter___createdDate, order: DESC }
      filter: { frontmatter: { category: { eq: $category }, contentType: { in: ["wishes", "poems", "gift-ideas"] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            createdDate(formatString: "DD [<span>] MMMM [</span>]")
            title
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 570, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    imageCards: allMdx(
      limit: 2000
      sort: { fields: frontmatter___createdDate, order: DESC }
      filter: { frontmatter: { category: { eq: $category }, contentType: { eq: "e-cards" } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            createdDate(formatString: "DD [<span>] MMMM [</span>]")
            title
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 570, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
