import { Link } from 'gatsby';
import styled from 'styled-components';

 export const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 30px 0;

  @media (min-width: 990px) {
    width: 900px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (max-width: 990px) {
    padding: 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 30px 25px;
    font-size: 12px;
  }
 `;

 export const ArrowSeparator = styled.div`
    margin: 0 5px;
    &::before {
      content: ">";
      color: #d10068;
    }
 `;

 export const BreadcrumbLink = styled(Link)`
    color: #757575;
    transition: color 0.3s;
    text-decoration: underline;
    text-underline-offset: 1px;
    text-transform: capitalize;

    &:hover {
      color: #292929;
      text-decoration: underline;
    }
 `;

 export const BreadcrumbStatic = styled.span`
    color: #757575;
    text-transform: capitalize;

    @media (max-width: 575px) {
      display: none;
    }
 `;