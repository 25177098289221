import React from 'react';
import { ArrowSeparator, BreadcrumbLink, BreadcrumbsContainer, BreadcrumbStatic } from './breadcrumbs.style';

const Breadcrumbs = ({ data }) => {
  return (
    <BreadcrumbsContainer>
      <BreadcrumbLink to="/">Home</BreadcrumbLink>
      {data.map((linkDetail) => (
        <>
          <ArrowSeparator />
          {linkDetail.link ? (
            <BreadcrumbLink to={linkDetail.link}>{linkDetail.label}</BreadcrumbLink>
          ) : (
            <BreadcrumbStatic>{linkDetail.label}</BreadcrumbStatic>
          )}
        </>
      ))}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
